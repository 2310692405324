import '@clickbar/tailwindui-vue/style.css'
import '@fontsource-variable/inter'
import { Link, createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, h } from 'vue'

import './tailwind.css'
import sentryVuePlugin from '@/app/sentry'

import type { DefineComponent } from 'vue'

const appName = window.document.querySelectorAll('title')[0].textContent ?? 'Laravel'

void createInertiaApp({
  title: (title: string) => `${title} - ${appName}`,
  progress: {
    color: '#4B5563',
  },

  resolve: (name: string) =>
    resolvePageComponent(
      `../pages/${name}.vue`,
      import.meta.glob<DefineComponent>('../pages/**/*.vue'),
    ),

  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(sentryVuePlugin)
      .component('inertia-link', Link)
      .component('InertiaLink', Link)
      .mount(el)
  },
})
